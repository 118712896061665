<template>
  <Card v-auto-animate>
    
<FormKitLazyProvider config-file="true">
<div @click="onCollapse" class="w-full justify-between flex items-center" :class="{'group cursor-pointer': canCollapse, 'mb-4': !collapsed }" v-auto-animate>
      <GuestInfoChip
        :guest="guestData"
        :guest-maturity="formMaturity"
        :guestCount="guestCount"
        :hasError="formHasError"
        :isCollapsed="collapsed"
      />
      <div class="group-hover:text-navy-500 text-navy" v-if="canCollapse">
        <PhosphorIconCaretDown v-if="collapsed" size="24" />
        <PhosphorIconCaretUp v-else size="24" />
      </div>
    </div>

    <FormKit
      v-auto-animate
      v-if="!collapsed"
      v-bind="formkitSubmitOptions"
      v-model="formModel"
      type="form"
      :id="formName"
      :name="formName"
      @submit="onSubmit"
      @submit-invalid="onSubmitInvalid"
      @input="onInput"
    >
      <FormKitSchema :schema="combinedSchema"  />
    </FormKit>
</FormKitLazyProvider>

  </Card>
</template>

<script lang="ts" setup>
import { FormKitLazyProvider } from '@formkit/vue'
import { buildFormkitObject, generateFormkitSchema, type SchemaFlags } from './checkInFormUtils'
import { FormKitSchema, useFormKitContextById } from '@formkit/vue'
import { type FormKitSchemaDefinition, getNode } from '@formkit/core'
import type { Guest } from '~/types/checkIn';

import { useStorage } from '@vueuse/core'

const emit = defineEmits(['initForm', 'formComplete', 'formEdit', 'formStopEdit'])

const layoutWidth = inject<Ref>('layoutWidth')
const props = defineProps<{
  guest: Guest;
  canCollapse?: boolean
  guestCount?: number
}>()

const { updateGuest } = useWebsiteStore()
const { getFormStateByGuestId, formByGuestId } = useCheckInStore()

const collapsed = ref(false)
const formHasError = ref(false)

const guestData = toRef(props, 'guest')

const formName = computed(() => `${guestData.value?.guestId}`)
const formInstance = useFormKitContextById(formName.value)

const localFormModel = useStorage(`shackle-${guestData.value?.guestId}`, {})

const formModel = ref()
const formMaturity = ref()

const schemaDisabled = computed(() => {
  const { isComplete, isEditing } = getFormStateByGuestId(guestData.value?.guestId)
  if (isComplete) return true
  if (formHasError.value || isEditing) return false
});

const flags = computed<SchemaFlags>(() => {
  return {
    ...getFormStateByGuestId(guestData.value?.guestId),
    isDisabled: schemaDisabled.value ?? false,
    isLargeLayout: layoutWidth?.value > 640,
    formName: formName?.value,
    isPrimary: formModel.value?.guestType === 'PRIMARY',
    isChild: formMaturity.value === 'child',
  }
})

const processedForm = computed<FormKitSchemaDefinition>(() =>  generateFormkitSchema(guestData.value, flags.value))
const formFields = computed(() => formByGuestId(guestData.value?.guestId)?.field.map((field: any) => buildFormkitObject(field, flags.value)))
const combinedSchema = computed(() => {
  const processedFormMap = new Map(Array.isArray(processedForm.value) ? processedForm.value.map((field: any) => [field.props?.id, field]) : [])
  const formSchemaMap = new Map(formFields.value?.map((field: any) => [field.props?.id, field]) || [])
  const combinedFields = new Map([...processedFormMap, ...formSchemaMap]).values()
  return Array.from(combinedFields)
})

const formkitSubmitOptions = computed(() => {
  const { isComplete, isInitialised } = getFormStateByGuestId(guestData.value?.guestId)

  let submitLabel = 'Edit'

  if (!isInitialised && !isComplete)  submitLabel = 'Start'
  if (isInitialised && !isComplete)   submitLabel = 'Confirm'
  if (!isInitialised && isComplete)   submitLabel = 'Edit'

  const inputClass = isComplete ? 'hover:bg-teal-600 bg-teal-500' : 'hover:bg-navy-900 bg-navy'

  return {
    disabled: formHasError.value,
    submitLabel,
    submitAttrs: {
      inputClass: `${inputClass} text-white rounded-2xl leading-snug tracking-wide py-3`,
      wrapperClass: `flex justify-end`
    },
  }
})

onMounted(() => {
  collapsed.value = (props.canCollapse && getFormStateByGuestId(guestData.value?.guestId).isComplete) ? true : false
  formMaturity.value = dateToMaturity(guestData.value?.birthDate)
  if (localFormModel.value) formModel.value = localFormModel.value
})

const onInput = (formData: any) => {
  const exists = (key: string) => key in formData
  const wasUpdated = (key: string) => formData[key] !== formModel.value?.[key]

  if (exists('birthDate')) formMaturity.value = dateToMaturity(formData.birthDate)

  if (wasUpdated('nationality') && exists('document_issuing_country')) {
    getNode('document_issuing_country')?.input(formData.nationality)
  }

  const allowedFields = ['birthDate', 'countryOfResidence', 'nationality', 'email', 'firstName', 'lastName', 'guestType', 'guestId', 'status']
  const filteredFormData = Object.fromEntries(Object.entries(formData).filter(([key]) => allowedFields.includes(key)))
  const initialisedFormData = Object.fromEntries(Object.entries(formData).filter(([key]) => !allowedFields.includes(key)))

  updateGuest(filteredFormData as Guest)
  if (wasUpdated('countryOfResidence') && getFormStateByGuestId(formData.guestId).isInitialised) {
    emit('initForm', formData)
  }

  localFormModel.value = initialisedFormData
}

const onCollapse = () => {
  // fixes a bug where the form is not revalidated when collapsed
  if (collapsed.value) formHasError.value = false 
  return props.canCollapse ? collapsed.value = !collapsed.value : null
}

const onSubmit = (formData: any) => {
  const { isComplete, isEditing, isInitialised } = getFormStateByGuestId(guestData.value?.guestId)

  if (formInstance.value) formHasError.value = !formInstance.value.state.valid
  
  if (formHasError.value) return
  
  if (isEditing && !isComplete) emit('formStopEdit')

  if (isComplete && !isEditing) {
    emit('formEdit')
    return
  }

  if (!isInitialised && !isComplete) {
    emit('initForm', formData)
    return
  }

  if (isInitialised && !isComplete) {
    formData.status = 'COMPLETED'
    emit('formComplete', formData)
    if (props.canCollapse) collapsed.value = true
    return
  }
}
const onSubmitInvalid = () => {
  // method must be defined - this scrolls to the first error using the formkit plugin
}
</script>

<style scoped>
:deep(.formkit-form) {
  --fk-gap: 1rem;
  gap: var(--fk-gap);
  @media screen and (min-width: 640px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

:deep(.formkit-outer + .formkit-outer) {
  @media screen and (max-width: 640px) {
    margin-top: calc(var(--fk-gap));
  }
}

:deep(.formkit-actions .formkit-input) {
  margin-top: var(--fk-gap);
  @media screen and (min-width: 640px) {
    max-width: 50%;
  }
}

:deep(.formkit-actions),
:deep(.formkit-messages) {
  grid-column: span 2;
  order: 1000;
  margin-top: calc(var(--fk-gap) / 2);
}

:deep([data-invalid] .formkit-label) {
  color: #eb3a3a;
}

:deep([data-invalid] .formkit-input) {
  border-color: #eb3a3a;
}

:deep([data-disabled] .formkit-input) {
  cursor: not-allowed;
  background-color: inherit;
  opacity: .5;
}

:deep([data-complete] .formkit-input) {
  border-color: #2CABB1;
}

:deep([data-invalid] .formkit-input) {
  padding-right: calc(var(--fk-gap) * 2);
}

:deep([data-complete] .formkit-input) {
  padding-right: calc(var(--fk-gap) * 2);
}

:deep(.formkit-inner::before),
:deep(.formkit-inner::after) {
  pointer-events: none;
  position: absolute;
  width: 20px;
  height: 20px;
  right: calc(var(--fk-gap) / 2);
  top: calc(50% - 1px); /**1px accounts for border */
  transform: translateY(-50%);
  transition: all 400ms cubic-bezier(0.075, 0.82, 0.165, 1);
  opacity: 1;
}

:deep([data-type="select"][data-invalid] .formkit-inner::before),
:deep([data-type="select"][data-complete] .formkit-inner::before) {
  right: calc(var(--fk-gap) * 2);
}

:deep([data-type="select"] .formkit-inner:before) {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M16.25 7.5 10 13.75 3.75 7.5'/%3E%3C/svg%3E");
}

:deep([data-type="select"][data-disabled] .formkit-inner::before) {
  opacity: 0.5;
}

:deep([data-type="select"] .formkit-input) {
  padding-right: calc(var(--fk-gap) * 2);
}

:deep([data-type="select"][data-complete] .formkit-input) {
  padding-right: calc(var(--fk-gap) * 3.5);
}

:deep([data-type="date"][data-complete] .formkit-input) {
  padding-right: calc(var(--fk-gap) * 2);
}

:deep([data-invalid] .formkit-inner::after) {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath fill='%23eb3a3a' d='M10 1.875A8.125 8.125 0 1 0 18.125 10 8.14 8.14 0 0 0 10 1.875ZM9.375 6.25a.625.625 0 0 1 1.25 0v4.375a.624.624 0 1 1-1.25 0V6.25ZM10 14.375a.938.938 0 1 1 0-1.875.938.938 0 0 1 0 1.875Z'/%3E%3C/svg%3E");
}

:deep([data-complete] .formkit-inner::after) {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath fill='%232CABB1' d='M10 1.875A8.125 8.125 0 1 0 18.125 10 8.14 8.14 0 0 0 10 1.875Zm3.867 6.703L9.29 12.953a.633.633 0 0 1-.867 0l-2.29-2.187a.624.624 0 1 1 .86-.907l1.86 1.774 4.156-3.961a.625.625 0 0 1 .86.906Z'/%3E%3C/svg%3E");
}

:deep([data-type="checkbox"] .formkit-wrapper) {
  grid-template-columns: auto 1fr;
  gap: calc(var(--fk-gap) / 2);
}
:deep([data-type="checkbox"] .formkit-inner::after) {
  content: none;
}

/* safari date input consistency fixes. todo: date icon for safari only */
:deep(input::-webkit-date-and-time-value) {
  text-align: left;
}
:deep(input[type="date"]:empty) {
  min-height: 40px;
}
</style> 