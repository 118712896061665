<template>
  <Card v-auto-animate sticky-footer v-if="ready">

    <div class="space-y-2 @md/card:space-y-4">
      <h2 class="text-slate-800 text-xl font-normal tracking-wide leading-[24px]">Please enter your details</h2>
      <p class="text-gray-600 font-thin text-sm tracking-wide leading-snug">The hotel requires this information for all guests.</p>
    </div>

    <CheckInForm 
      v-if="primaryGuest"
      :guest="primaryGuest"
      @init-form="(formData: any) => onFormInit(formData)"
      @form-complete="(formData: Guest) => onFormComplete(formData, primaryForm)"
      @form-edit="editForm(primaryGuest as Guest)"
      @form-stop-edit="onFormStopEdit(primaryGuest as Guest)"
    />
    
    <template v-if="secondaryForms.length > 0">
      <div class="space-y-2 @md/card:space-y-4">
        <h2 class="text-slate-800 text-xl font-normal tracking-wide leading-[24px]">Please enter additional guests details</h2>
        <p class="text-gray-600 font-thin text-sm tracking-wide leading-snug">The hotel requires this additional information for guests to be able to process your check-in.</p>
      </div>

      <CheckInForm
        can-collapse
        v-for="({ guest, form }, index) in secondaryForms" 
        :key="(guest as Guest)?.guestId"
        :guest="(guest as Guest)"
        :guest-count="index + 1"
        @init-form="(formData: any) => onFormInit(formData)"
        @form-complete="(formData: Guest) => onFormComplete(formData, form)"
        @form-edit="editForm(guest as Guest)"
        @form-stop-edit="onFormStopEdit(guest as Guest)"
      />
    </template>

    <div class="text-zinc-500 text-xs">
      <p>*By completing online check-in, you are accepting the Hotel’s Policy.</p>
      <details class="mt-1" v-if="property?.termsAndConditions">
        <summary class="cursor-pointer text-teal underline">Read Full Policy here</summary>
        <div class="p-2 border rounded-md bg-gray-50 mt-1" v-html="property?.termsAndConditions?.replaceAll('\n', '<br/>')"></div>
      </details>
    </div>

    <template #footer>
      <div class="bg-white w-full p-3 border-t" ref="footerWrapper">
        <SButton :disabled="!canContinue" class="w-full" @click="onProceed">Complete check-in</SButton>
      </div>
    </template>
  </Card>
</template>

<script lang="ts" setup>
const { $mixpanel } = useNuxtApp();
import { MP_FORM_INIT, MP_FORM_SUBMITTED } from '~/plugins/mixpanel.client';

import type { Guest } from '~/types/checkIn';
import { buildFormPayload } from './checkInFormUtils'
import { useIntersectionObserver } from '@vueuse/core';

const { 
  editForm,
  unEditForm,
  checkIn, 
  formByGuestId, 
  guestById, 
  submitForm, 
  acceptTermsAndCondition,
  initializeRegistrationForm,
} = useCheckInStore()

const { 
  property, 
  guestCount, 
  allGuestsCompleted, 
  reservation, 
  primaryGuest, 
  secondaryGuestIDs, 
  reservationRequiresPayment
} = storeToRefs(useWebsiteStore())

const footerWrapper = ref<HTMLElement | null>(null)
useIntersectionObserver(footerWrapper, (entries) => {
  if (entries[0].intersectionRatio < 1) {
    footerWrapper.value?.classList.add('border-t')
  } else {
    footerWrapper.value?.classList.remove('border-t')
  }
}, {
  threshold: [0, 1]
})

const emit = defineEmits(['proceed'])

const ready = ref(false)
onMounted(() => { ready.value = true })

const canContinue = computed(() => allGuestsCompleted.value && guestCount.value > 0)

const primaryForm = computed(() => formByGuestId(primaryGuest.value?.guestId))
const secondaryForms = computed(() => {
  if (!secondaryGuestIDs.value) return []
  const forms = []
  for (const guestId of secondaryGuestIDs.value) {
    forms.push({
      form: formByGuestId(guestId),
      guest: guestById(guestId)
    })
  }
  return forms
})

const editingForms = ref<string[]>([])
const onFormStopEdit = (guest: Guest) => {
  editingForms.value = editingForms.value.filter((guestId) => guestId !== guest.guestId)
  unEditForm(guest)
}

const onFormInit = async (formData: any) => {
  await initializeRegistrationForm(formData)
  $mixpanel(MP_FORM_INIT, { formData })
}

const onFormComplete = async (formData: any, form: any) => {
  const payload = buildFormPayload(formData, form)
  await submitForm(payload);
  $mixpanel(MP_FORM_SUBMITTED, { formData })
}

const onProceed = async () => {
  const needsPreauth = reservationRequiresPayment.value
  if (needsPreauth) {
    navigateTo('/payment')
  } else {
    if (reservation.value) {
      await acceptTermsAndCondition(reservation.value.name)
      await checkIn(reservation.value.name)
      navigateTo('/confirmation')
    }
  }
}
</script>