<template>
  <div class="space-x-2 capitalize items-center flex text-sm">
    <div :class="classes.guest" class="border rounded-xl py-1 px-3 min-w-16 border-current text-navy">
      <span>{{ guest?.guestType?.toLowerCase() }}</span>
      <span>
        Guest <span v-if="guestCount">{{ guestCount }}</span>
      </span>
    </div>
    <PhosphorIconWarningCircle size="24" v-if="hasError && !isComplete" weight="fill" class="text-alert-danger" />
    <PhosphorIconCheckCircle size="24" v-if="isComplete" weight="fill" class="text-teal-500" />

    <span v-if="guestFullName.valid">{{ guestFullName.text }}</span>
    <div v-else-if="canShowMaturity && guestMaturity" class="border rounded-xl py-1 px-3 border-current text-navy" :class="classes.maturity">{{ guestMaturity }}</div>
  </div>
</template>

<script setup lang="ts">
import type { Guest } from '~/types/checkIn';

const props = defineProps<{
  guest?: Guest;
  guestCount?: number
  guestMaturity?: 'adult' | 'child'
  hasError?: boolean
  isCollapsed?: boolean
}>()

const { guest, guestCount, hasError, isCollapsed, guestMaturity } = toRefs(props)

const { getFormStateByGuestId } = useCheckInStore()
const isComplete = computed(() => {
  return getFormStateByGuestId(guest.value?.guestId ?? '')?.isComplete ?? false
})

const classes = computed(() => {
  return {
    guest: {
      ['bg-alert-danger text-white']: hasError.value,
      ['bg-teal-500 text-white']: isComplete.value,
      ['text-white bg-navy']: !isCollapsed.value && !hasError.value && !isComplete.value
    },
    maturity: {
      ['text-cyan-600']: guestMaturity.value === 'adult',
      ['text-teal-500']: guestMaturity.value === 'child'
    }
  }
})

const canShowMaturity = computed(() => guest.value?.birthDate !== undefined && guest.value?.birthDate !== '')

const guestFullName = computed(() => {
  const valid = guest.value?.firstName && guest.value?.lastName
  return {
    valid,
    text: `${guest.value?.firstName} ${guest.value?.lastName}`.trim()
  }
})
</script>