<template>
  <div class="container flex flex-col lg:flex lg:flex-row-reverse items-start gap-4" v-if="ready">
    <StayDetailCard
      hide-footer
      has-border
      class="lg:sticky top-4 lg:max-w-sm"
      layout="compact"
    />
    <CheckInFormCard class="self-start"/>
  </div>
</template>

<script lang="ts" setup>
const { getReservation, getListGuestsForReservation } = useWebsiteStore();
const { isClaimFetched, reservation } = storeToRefs(useWebsiteStore())
const { setTheme } = usePoweredByTheme()
definePageMeta({
  middleware: '01-is-authenticated'
})
const ready = ref(false)
onMounted(async () => {
  setTheme('dark')

  const reservationStage = localStorage.getItem('reservationStage')
  const reservationName = localStorage.getItem('reservationName')
  const confirmation = localStorage.getItem('shackle-confirmation')
  const alreadyCheckedIn = ['PENDING_CHECK_IN', 'CHECKED_IN'].includes(String(reservationStage))

  if (!isClaimFetched.value) navigateTo('/')
  ready.value = true

  if (confirmation && JSON.parse(confirmation).name === reservation.value?.name || alreadyCheckedIn) {
    navigateTo('/confirmation')
  }

  if (isClaimFetched.value && reservationName) {
    await getReservation(String(reservationName));
    await getListGuestsForReservation()
  }
})
</script>